@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.section {
  & h3 {
    font-weight: 600;
    font-size: $text-xl;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
  }
}

.item {
  border-top: 1px solid $color-black;
  padding-top: 1.5rem;
  padding-bottom: 1.3rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 2.1rem;

  @include respond(tab-port) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.download {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  & a {
    color: $color-default;
    text-decoration: none;
  }

  & :hover {
    text-decoration: underline;
    color: $color-link-hover;
  }
}

.itemsWrapper {
  display: flex;
  justify-content: center;

  .item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div:first-child,
    div:last-child {
      width: 100%;
      text-align: left;
    }
  }
}

.softwareInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.6rem;

  & p {
    flex-wrap: wrap;
    white-space: nowrap;
  }

  @include respond(tab-port) {
    justify-content: flex-start;
  }
}
