@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.singleImageWrapper {
  margin-bottom: 16rem;

  & img {
    width: 100%;
    height: auto;

    @include respond(big-desktop) {
      object-fit: cover;
    }

    @include respond(mobile) {
      object-fit: cover;
      aspect-ratio: 1/1;
      height: 100%;
      width: 100%;
    }
  }
}
