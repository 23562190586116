@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.wrapper {
  margin-bottom: 16rem;

  @include respond(mobile) {
    margin-bottom: 7rem;
  }
}

.containerWithImage {
  margin-block: 16rem;
  padding-top: 10.5rem;
  padding-bottom: 6.1rem;
  padding-inline: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.4rem;
  background-color: $color-background;

  @include respond(tablet) {
    margin-block: 6rem;
  }

  @include respond(tab-port) {
    margin-block: 2rem;
  }
}

.container {
  margin-block: 16rem;
  padding-block: 13rem;
  padding-inline: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.4rem;
  background-color: $color-background;

  @include respond(tablet) {
    margin-block: 6rem;
  }

  @include respond(tab-port) {
    margin-block: 2rem;
  }

  @include respond(mobile) {
    padding-block: 7rem;
  }
}

.textContentWithImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

.subtitle {
  font-weight: 400;
  font-size: $text-xl;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.title {
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 4.2rem;
  max-width: 50rem;
  text-align: center;

  @include respond(tablet) {
    font-size: $text-2xl;
    line-height: 4rem;
    max-width: 45rem;
  }
}

.text {
  margin-top: 1.6rem;
  font-weight: 400;
  font-size: $text-base;
  line-height: 2.4rem;
  max-width: 66rem;
  text-align: center;
  white-space: pre-wrap;

  @include respond(tablet) {
    font-size: $text-xl;
    line-height: 2.8rem;
  }
}

.imageWrapper {
  & img {
    @include respond(tablet) {
      width: 100%;
      height: 13rem;
    }

    @include respond(tab-port) {
      height: 12rem;
    }
  }
}
