@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.headlineSection {
  margin: 6.4rem auto;
  width: 100%;

  @include respond(tablet) {
    margin: 3.2rem auto;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include respond(tablet) {
    gap: 1rem;
  }
}

.title {
  font-size: $text-5l;
  font-weight: 450;

  @include respond(tablet) {
    font-size: $text-4xl;
    line-height: 5rem;
  }
}

.line {
  border: 0;
  height: 1px;
  width: 100%;
  background: $color-black;
}

.subtitle {
  color: $color-default;
  font-size: $text-2xl;
  font-weight: 450;
}
