// COLORS
$color-black: #000;
$color-white: #fff;
$color-default: #0f0f0f;
$color-light-grey: #666666;
$color-golden-tainoi: #ffcc66;
$color-background: #f5f5f5;
$color-soft: #767676;
$color-error: #d86c70;
$color-success: #008169;
$color-lighter-grey: #e9e7e7;
$color-heather: #8a2be2;
$color-orange: #ec420d;
$color-dusty-gray: #949494;
$color-background: #eff0f0;
$color-light: #f2f2f2;
$color-dark-grey: #767676;
$color-space-grey: #7e7e7e;
$color-gainsboro: #e1e1e1;
$color-silver: #c0c0c0;
$color-border: #dddddd;
$color-disabled: #c0bec0;
$color-link-hover: #0000cd;

// FONT
$text-xs: 1.2rem;
$text-sm: 1.4rem;
$text-base: 1.6rem;
$text-lg: 1.8rem;
$text-xl: 2rem;
$text-2xl: 2.4rem;
$text-3xl: 3.2rem;
$text-4xl: 3.6rem;
$text-5l: 4.4rem;
$text-5xl: 4.8rem;
$text-6xl: 6rem;
$text-7xl: 7.2rem;

$content-padding: 6.8rem 8.4rem;
$content-padding-mobile: 2rem;
