// MEDIA QUERY MANAGER
/*
0 - 600px:      Mobile
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800]:  is where our normal styles apply
1800px +:      Big desktop
$breakpoint arguement choices:
- mobile
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == mobile {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 62.4375em) {
      @content;
    } //999px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}

@mixin links-wrapper {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.85rem;
}

@mixin link-style {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & a {
    font-weight: 500;
    line-height: 1.9rem;
    color: $color-black;
    text-decoration: none;

    @include respond(tablet) {
      font-size: $text-xl;
      line-height: 2.6rem;
    }
  }
}
