@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  margin-bottom: 16rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  @include respond(tablet) {
    margin-bottom: 2rem;
    flex-direction: column;
    gap: 1.2rem;
  }
}

.greyBackground {
  background-color: $color-background;
  padding-bottom: 15rem;

  @include respond(mobile) {
    padding-bottom: 7rem;
  }
}

.imageWrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 9.7rem;
  margin-bottom: 7rem;
  gap: 3rem;
  padding-inline: 10rem;

  @include respond(tablet) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include respond(mobile) {
    padding-inline: 3.2rem;
  }
}

.img {
  width: 100% !important;
  height: auto;
}

.textContent {
  max-width: 62rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @include respond(big-desktop) {
    max-width: 63rem;
  }

  @include respond(tablet) {
    gap: 2rem;
  }

  @include respond(mobile) {
    padding-inline: 4rem;
    max-width: 100%;
  }
}

.imgContainer {
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 4.2rem;
  text-align: center;

  @include respond(tablet) {
    font-size: 4rem;
    line-height: 5.25rem;
    text-align: center;
  }
}

.text {
  margin-top: 0.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
  white-space: pre-wrap;

  @include respond(tablet) {
    margin-top: 1rem;
    font-size: $text-xl;
    line-height: 2.8rem;
  }
}
