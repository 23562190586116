@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.containerWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16rem;
  padding-inline: 5rem;

  @include respond(tablet) {
    gap: 6rem;
  }

  @include respond(tablet) {
    flex-direction: column;
    gap: 5.6rem;
  }

  @include respond(mobile) {
    padding-inline: 0;
    margin-bottom: 7rem;
  }
}

.container,
.reverseContainer {
  color: $color-default;

  @include respond(big-desktop) {
    margin: 0 auto;
    max-width: 2000px;
  }

  @include respond(tablet) {
    padding-block: 6rem;
  }

  @include respond(tab-port) {
    padding-block: 4rem;
  }
}

.smallerImg {
  & img {
    max-height: 47.1rem;

    @include respond(big-desktop) {
      max-height: 60.1rem;
    }

    @include respond(tablet) {
      max-height: 75.1rem;
    }
  }
}

.reverseContainer {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 12rem;

  @include respond(tablet) {
    gap: 6rem;
  }

  @include respond(tab-port) {
    flex-direction: column;
    gap: 5.6rem;
  }
}

.rightPadding {
  padding-left: 0 !important;
  padding-right: 3.4rem;

  @include respond(big-desktop) {
    padding-right: 0;
  }

  @include respond(tablet) {
    padding-right: 0;
  }
}

.textContent {
  padding-left: 8.4rem;
  flex: 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  max-width: 50rem;

  @include respond(big-desktop) {
    max-width: 44rem;
  }

  @include respond(tab-land) {
    padding-left: 6.4rem;
  }

  @include respond(tablet) {
    padding-left: 0;
    gap: 1rem;
  }

  @include respond(tablet) {
    max-width: 100%;
  }
}

.widerBox {
  max-width: 44rem;

  @include respond(tab-port) {
    max-width: 100%;
  }
}

.title {
  font-weight: 600;
  font-size: $text-3xl;
  line-height: 4.2rem;

  @include respond(tablet) {
    line-height: 4rem;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 7.4rem;
  width: 89%;

  @include respond(tablet) {
    width: 100%;
  }
}

.text {
  margin-top: 0.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  white-space: pre-wrap;

  & p {
    margin-bottom: 2rem;
  }

  & a {
    color: $color-black;
  }

  @include respond(tablet) {
    margin-top: 1rem;
    font-size: $text-xl;
    line-height: 2.8rem;
  }

  @media only screen and (max-width: 360px) {
    font-size: $text-lg;
  }
}

.imageWrapper {
  flex: 0 50%;

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.higherImg {
  flex: 0 50%;

  & img {
    aspect-ratio: 608/770;
    @include respond(tablet) {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}

.btn {
  margin-top: 3.3rem;
  padding: 1.2rem 2.4rem;
  width: fit-content;
  text-align: center;

  @include respond(tablet) {
    font-size: $text-xl;
    line-height: 2.4rem;
  }
}
